import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./en.json";
import nl from "./nl.json";
import de from "./de.json";
import es from "./es.json";
import it from "./it.json";
import fr from "./fr.json";

Vue.use(VueI18n);

const numberFormats = {
  en: {
    currency: {
      style: "currency",
      currency: "EUR",
    },
  },
};

export const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: { en, es, nl, de, fr, it },
  numberFormats,
  silentTranslationWarn: true,
});

export function changeLanguage(lang) {
  if (i18n.messages[lang]) i18n.locale = lang;
}

export function getCurrentLanguage() {
  return i18n.locale;
}
