import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueSignature from "vue-signature-pad";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/bootstrap-overrides.css";
import "./firebase";
import { getHubspotCountryTag } from "./utilities";

import BootstrapVue from "bootstrap-vue";

Vue.use(BootstrapVue);
Vue.use(VueSignature);

Vue.config.productionTip = false;

import icons from "./icons.js"; //eslint-disable-line
import { i18n } from "./lang/i18n.js";

new Vue({
  data() {
    return {
      hubspotCountry: "GLOBAL",
    };
  },
  async beforeCreate() {
    this.hubspotCountry = await getHubspotCountryTag();
  },
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
