import Vue from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlus,
  faTrashAlt,
  faBox,
  faTruckMoving,
  faFileInvoice,
  faMapPin,
  faSearch,
  faDownload,
  faTimes,
  faCopy,
  faAngleDown,
  faCheck,
  faStore,
  faAngleRight,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add([
  faArrowDown,
  faPlus,
  faStore,
  faTrashAlt,
  faBox,
  faTruckMoving,
  faFileInvoice,
  faMapPin,
  faSearch,
  faCheck,
  faDownload,
  faTimes,
  faAngleRight,
  faAngleDown,
  faCopy,
]);

import {
  /* HS Code Index */
  faCalendarCheck,
  faCarrot,
  faPaw,
  faLeaf,
  faUtensils,
  faBurn,
  faAtomAlt,
  faBallPile,
  faSkullCow,
  faTrees,
  faScroll,
  faExclamationCircle,
  faTshirt,
  faShoePrints,
  faGlass,
  faGem,
  faHammerWar,
  faPlug,
  faCar,
  faWatch,
  faSwords,
  faCouch,
  faSpinnerThird,
  faLandmark,
  faRoute,
} from "@fortawesome/pro-solid-svg-icons";

import {
  faBoxAlt,
  faEnvelope,
  faBox as faBoxLight,
} from "@fortawesome/pro-light-svg-icons";

library.add([
  faBoxLight,
  faBoxAlt,
  faRoute,
  faEnvelope,
  faCalendarCheck,
  faCarrot,
  faPaw,
  faLeaf,
  faUtensils,
  faExclamationCircle,
  faAtomAlt,
  faBallPile,
  faSkullCow,
  faTrees,
  faBurn,
  faScroll,
  faSpinnerThird,
  faTshirt,
  faShoePrints,
  faGlass,
  faGem,
  faHammerWar,
  faPlug,
  faCar,
  faWatch,
  faSwords,
  faCouch,
  faLandmark,
]);

Vue.component("font-awesome-icon", FontAwesomeIcon);
