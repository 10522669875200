import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { changeLanguage } from "@/lang/i18n.js";

import vueCountryRegionSelect from "vue-country-region-select";
Vue.use(vueCountryRegionSelect);

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/customs-forms",
    name: "customs-forms",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "cn23" */ "../views/CN23.vue"),
  },
  {
    name: "hs-codes",
    path: "/hs-codes",
    component: () =>
      import(/* webpackChunkName: "hs-codes" */ "../views/HSCodes/index.vue"),
  },
  {
    path: "/hs-codes/sections/:section",
    name: "hs-code-section",
    component: () =>
      import(
        /* webpackChunkName: "hs-code-section" */ "../views/HSCodes/Sections/section.vue"
      ),
  },
  {
    path: "/hs-codes/chapters/:chapter",
    name: "hs-code-chapter",
    component: () =>
      import(
        /* webpackChunkName: "hs-code-chapter" */ "../views/HSCodes/Chapters/chapter.vue"
      ),
  },
  {
    path: "/hs-codes/codes/:code",
    name: "hs-code-detail",
    component: () =>
      import(
        /* webpackChunkName: "hs-code-detail" */ "../views/HSCodes/Codes/code.vue"
      ),
  },
  {
    path: "/shipping-label-generator",
    name: "shipping-label-generator",
    component: () =>
      import(
        /* webpackChunkName: "shipping-label-generator" */ "../views/ShippingLabelGenerator.vue"
      ),
  },
  {
    path: "/returns-policy-generator",
    name: "returns-policy-generator",
    component: () =>
      import(
        /* webpackChunkName: "returns-policy-generator" */ "../views/ReturnsPolicyGenerator.vue"
      ),
  },
  {
    path: "/shipping-costs-calculator",
    name: "shipping-costs-calculator",
    component: () =>
      import(
        /* webpackChunkName: "shipping-costs-calculator" */ "../views/ShippingCostsCalculator.vue"
      ),
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const lang = to.query.lang;
  if (lang) changeLanguage(lang);
  next();
});

export default router;
