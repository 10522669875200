// Initialize Firebase
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBMfTwjYmEMlv7Olh2MZ-0V3yL68X271v0",
  authDomain: "sendcloud-tools.firebaseapp.com",
  databaseURL: "https://sendcloud-tools.firebaseio.com",
  projectId: "sendcloud-tools",
  storageBucket: "sendcloud-tools.appspot.com",
  messagingSenderId: "484566549442",
  appId: "1:484566549442:web:28b9815940779ab0518c5b",
};

const app = initializeApp(firebaseConfig);
export default app;
