import axios from "axios";

/**
 * Gets the country code to use for pushing data to Hubspot. Uses IP geolocation or browser language.
 * If the country code is not one in which Sendcloud does business, it uses GLOBAL as fallback.
 */
export const getHubspotCountryTag = async () => {
  const supportedCountryTags = ["UK", "NL", "DE", "FR", "ES", "IT", "BE", "AT"];
  let countryTagToUse;
  let countryCodeFromIP;
  let countryCodeFromBrowser;

  // A. Fetch the country code using paid IP API service.
  const ipApiResponse = await axios.get(
    "https://geo.ipify.org/api/v1?apiKey=at_0qbQoucbkDT4YxGKPFJIuJTBCrIzZ"
  );
  countryCodeFromIP = ipApiResponse.data?.location?.country;

  // B. As fallback, use the country code from browser.
  const browserLang = navigator.language;
  const browserLangCountry = browserLang.split("-");
  countryCodeFromBrowser = browserLangCountry[1] || browserLangCountry[0];

  let countryCode = countryCodeFromIP || countryCodeFromBrowser;

  if (countryCode.toLowerCase() === "gb") {
    // If country code is GB, set as UK due to internal usage of UK instead of GB.
    countryCode = "uk";
  }

  // Check if country code is a supported one by SC. If not, use GLOBAL.
  if (countryCode && supportedCountryTags.includes(countryCode.toUpperCase())) {
    countryTagToUse = countryCode;
  } else {
    countryTagToUse = "GLOBAL";
  }
  return countryTagToUse.toUpperCase();
};
