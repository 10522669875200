<template>
  <section>
    <b-container class="text-center">
      <img class="logo" src="/sendcloud-logo.svg" />
      <!-- <h1>Sendcloud Tools</h1> -->
      <!-- <p>Use these free tools to improve your shipping efficiency. 🙌🏻</p> -->

      <b-list-group class="list">
        <router-link to="/customs-forms">
          <b-list-group-item button>Customs Forms Generator</b-list-group-item>
        </router-link>
        <router-link to="/hs-codes">
          <b-list-group-item button>HS/CN Code Finder</b-list-group-item>
        </router-link>
        <router-link to="/shipping-label-generator">
          <b-list-group-item button>Shipping Label Generator</b-list-group-item>
        </router-link>
        <router-link to="/returns-policy-generator">
          <b-list-group-item button>Returns Policy Generator</b-list-group-item>
        </router-link>
        <router-link to="/shipping-costs-calculator">
          <b-list-group-item button
            >Shipping Costs Calculator</b-list-group-item
          >
        </router-link>
      </b-list-group>
      <small>
        Questions or suggestions? Send an email to:
        <a href="mailto:'contact@sendcloud.sc'">contact@sendcloud.sc</a>
      </small>
    </b-container>
  </section>
</template>

<script>
import { BContainer, BListGroup, BListGroupItem } from "bootstrap-vue";

export default {
  name: "Home",
  components: {
    BContainer,
    BListGroup,
    BListGroupItem,
  },
};
</script>
<style scoped>
section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: bottom left / 20%
      url("https://faker.agency/sendcloud/images/blue-left.svg"),
    top right / 40% url("https://faker.agency/sendcloud/images/blue-right.svg");
  background-repeat: no-repeat;
}

.logo {
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.list {
  max-width: 450px;
  margin: 40px auto;
}

.list-group-item {
  border-bottom: none;
  border-radius: 0;
}

.list-group a:hover {
  text-decoration: none;
}

.list-group a:first-child .list-group-item {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group a:last-child .list-group-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
</style>
